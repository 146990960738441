.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Light background overlay */
    z-index: 9999; /* Make sure the spinner is on top of everything */
  }
  