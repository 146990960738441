.image-content {
  position: relative;
}
.text-left {
  position: absolute;
  top: 30px;
  left: 15px;
  padding: 3px;
  border-radius: 7px;
  font-size: 15px;
}
.text-right {
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 3px;
  border-radius: 7px;
  font-size: 15px;
}
.frame-heading {
  text-align: center;
  color: brown;
  font-weight: 600;
}
.price {
  color: lightgray;
  font-size: 15px;
}
.frame-price-details {
  display: flex;
  gap: 20px;
}
.frame-discount-price {
  color: red !important;
  display: flex;
  align-items: center !important;
}
.framing-card {
  height: 190px !important;
  object-fit: cover;
}
.framefilterdropdown {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 150px;
  margin-left: auto;
}

/* Responsive Design */

/* Medium Devices (Tablets, 768px and down) */
@media (max-width: 768px) {
  .framefilterdropdown {
    width: 100%;
    margin-right: 20px;
  }

  .frame-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .framing-card {
    height: 150px;
  }

  .frame-price-details {
    gap: 10px;
  }

  .text-left, .text-right {
    font-size: 12px;
  }
}

/* Small Devices (Phones, 576px and down) */
@media (max-width: 576px) {
  .framefilterdropdown {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none;
  }

  .frame-heading {
    font-size: 1.2rem;
  }

  .framing-card {
    height: 130px;
  }

  .frame-price-details {
    flex-direction: column;
    gap: 5px;
  }

  .text-left, .text-right {
    font-size: 10px;
  }

  .price, .discount-price {
    font-size: 12px;
  }

  Button {
    font-size: 14px;
  }
}
.image-content {
  position: relative;
}
.text-left {
  position: absolute;
  top: 30px;
  left: 15px;
  padding: 3px;
  border-radius: 7px;
  font-size: 15px;
}
.text-right {
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 3px;
  border-radius: 7px;
  font-size: 15px;
}
.frame-heading {
  text-align: center;
  color: brown;
  font-weight: 600;
}
.price {
  color: lightgray;
  font-size: 15px;
}
.frame-price-details {
  display: flex;
  gap: 20px;
}
.discount-price {
  display: flex;
  align-items: center !important;
}
.framing-card {
  height: 190px !important;
  object-fit: cover;
}
.framefilterdropdown {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 150px;
  margin-left: auto;
}

/* Responsive Design */

/* Medium Devices (Tablets, 768px and down) */
@media (max-width: 768px) {
  .framefilterdropdown {
    width: 100%;
    margin-right: 20px;
  }

  .frame-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .framing-card {
    height: 150px;
  }

  .frame-price-details {
    gap: 10px;
  }

  .text-left, .text-right {
    font-size: 12px;
  }
}

/* Small Devices (Phones, 576px and down) */
@media (max-width: 576px) {
  .framefilterdropdown {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none;
  }

  .frame-heading {
    font-size: 1.2rem;
  }

  .framing-card {
    height: 130px;
  }

  .frame-price-details {
    flex-direction: column;
    gap: 5px;
  }

  .text-left, .text-right {
    font-size: 10px;
  }

  .price, .discount-price {
    font-size: 12px;
  }

  Button {
    font-size: 14px;
  }
}
.heading-lines{
  padding: 25px !important;
}