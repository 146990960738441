.image-content {
    position: relative;
  }
  
  .text-left {
    position: absolute;
    top: 30px;
    left: 15px;
    padding: 3px;
    border-radius: 7px;
    font-size: 15px;
  }
  
  .text-right {
    position: absolute;
    top: 30px;
    right: 15px;
    padding: 3px;
    border-radius: 7px;
    font-size: 15px;
  }
  
  .mug-heading {
    text-align: center;
    color: brown;
    font-weight: 600;
  }
  
  .discount-price {
    color: lightgray !important;
    font-size: 15px;
  }
  
  .mug-price-details {
    display: flex;
    gap: 20px;
  }
  
  .mug-card {
    height: 190px !important;
  }
  
  @media (max-width: 768px) {
    /* Adjusting the product card size and text for tablet/small screens */
    .mug-card {
      height: 150px !important;
    }
    
    .mug-price-details {
      flex-direction: column;
      gap: 10px;
    }
  
    .text-left, .text-right {
      font-size: 12px;
      top: 20px;
    }
  
    .discount-price {
      font-size: 13px;
    }
  
    .frame-heading {
      font-size: 18px;
    }
  
    .framefilterdropdown {
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    /* For mobile screens */
    .mug-card {
      height: 120px !important;
    }
  
    .mug-price-details {
      flex-direction: column;
      gap: 5px;
    }
  
    .text-left, .text-right {
      font-size: 10px;
      top: 15px;
    }
  
    .frame-heading {
      font-size: 16px;
    }
  }
  .mug-heading-lines{
    padding: 25px !important;
  }
  