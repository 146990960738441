.image-container {
    position: relative;
    display: inline-block;
  }
  
  .image {
    display: block;
  }
  
  .magnifier {
    position: absolute;
    pointer-events: none; // Makes sure it doesn’t block mouse events
    z-index: 10; // To ensure it appears above the image
  }
  