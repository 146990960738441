.carausel-image {
    width: 100%;
    height: 60vh !important;
    object-fit: cover;
    .carousel-item{
      height: 60vh !important;
     
    }
  }
  #zero{
    height:600px !important;
  }
  @media (max-width: 1200px) {
    .carausel-image {
      height: 50vh;
    }
  }
  
  @media (max-width: 890px) {
    .carausel-image {
      height: 40vh;
    }
  }
  
  @media (max-width: 600px) {
    .carausel-image {
      height: 30vh;
    }
  }
  @media (max-width: 750px) {
    #zero{
      height:400px !important;
      object-fit: cover;
    }
  }
  @media (max-width: 400px) {
    #zero{
      height:200px !important;
      object-fit: cover;
    }
  }
  