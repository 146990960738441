.cartmain {
    .titlelist {
      font-size: 12px;
      color:rgb(18 18 18 / 40%);
    }
    .titlelistlast{
        font-size: 12px;
        justify-content: end;
        align-items: end;
        display: flex;
        color:rgb(18 18 18 / 40%);

    }
    .widthstyke{
        width: 100px !important;
    }
  
    .cartimage {
      width: 130px;
      display: block;
      object-fit: contain;
    }
  .cartplus{
    width: 13px;
      display: block;
      object-fit: contain;

  }
    .name {
      font-size: 14px;
      font-weight: 200;
      color: black !important;
      text-decoration: none !important;
    }
  
    .quantity-controls { 
      display: flex;
      align-items: center
    }
    .quantitybox{
        width: 200px;
        height: 40px;
        border: 1px solid black;
       
    }
  
    .quantity-controls .btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobileaddprice{
      
      display: flex;

    }

     .btns {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .quantity-controls .quantity {
      margin: 0 10px;
      font-size: 18px;
    }
  
    .total {
      font-size: 14px;
      
      justify-content: end;
      align-items: end;
      display: flex;
    }
  
    p {
      color: rgba(0, 0, 0, 0.75);
      position: relative;
      transition: color 0.3s, text-decoration 0.3s;
      text-decoration: none;
    }
  
    p::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #000000;
      transition: background-color 0.3s, height 0.3s;
    }
  
    p:hover::after {
      height: 2px;
      background-color: rgb(0, 0, 0);
    }

    .underlines {

        border: 0.5px solid rgb(18 18 18 / 8%);
        padding-top: 20px;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        margin-top: 30px;
    //     content: '';
       position: absolute;
    //     height: 5px;
    //     top: 280px;
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    //     background-color: red;
    //     //height: 0.5px;
    //    // background-color:rgb(18 18 18 / 8%);
    //     transition: background-color 0.3s, height 0.3s;
      }


      
  .underlinesest {

    border: 0.5px solid rgb(18 18 18 / 8%);
    padding-top: 20px;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin-top: -10px;
//     content: '';
   position: absolute;
//     height: 5px;
//     top: 280px;
width: 95%;
display: flex;
align-items: center;
justify-content: center;
//     background-color: red;
//     //height: 0.5px;
//    // background-color:rgb(18 18 18 / 8%);
//     transition: background-color 0.3s, height 0.3s;
  }

  .estimate{
    display: flex;
    justify-content: end;
    align-items: end;
  }
  h1{
    font-size: 15px;
  }
  .underline-text {
    text-decoration: underline;
  }
h2 {
  font-size:12px ;
}  

.checkoutmain{
 
width: 30%;
padding: 5px;
  
}



.checkout{
  color:white;
  font-size: 15px;
  display: flex;
  text-align: center;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
  }




  @media (max-width:792px) {
    .hidequa{
    display: none;
    
}

.estimate{
  //background-color: #000000;
  display: flex;
  justify-content:center !important;
  align-items: center !important;

}

.checkoutmain{
 
  width: 70% !important;

    
  }
.quantitybox{
    width: 100px !important;
    height: 40px;
    border: 1px solid black !important;
    
}


}

@media (min-width:792px) {
    .hidesec{
    display: none;
}
}

@media (max-width:540px) {
    .hidequa{
    display: none;
}



}

@media (max-width:455px) {
  .mobileaddprice{

  flex-direction: column;
  display: flex;
}
}

.continue{
  text-decoration: none !important;
}