
.sampleimage{

  width: 200px;
  
  
  object-fit: contain;
}
.sale-containerdiscountzero{
  
  background-color: rgba(235, 25, 25, 0.826);
  border-radius: 25px;
  font-size: 15px; 
  color: white;
  width: 80px;
  text-align: center;
  height: 20px;

}

// .arrivals{
  // padding-left: 150px;
  // padding-right: 150px;
// }

.sale-containerhover {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 60px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 232, 167, 0.826);
  border-radius: 25px;
  font-size: 15px;
  z-index: 9999;
}


.sale-containerhovernew {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 232, 167, 0.826);
  border-radius: 25px;
  font-size: 15px;
  z-index: 9999;
}

.sale-containerhover.unselected {
  color: gray; /* Change this to the desired color for unselected state */
}

.cartadd.selected {
  color: green; /* Change this to the desired color for selected state */
}

.cartadd.unselected {
  color: gray; /* Change this to the desired color for unselected state */
}

.cartaddbox.selected {
  background-color: #3C486B !important; /* Change this to the desired color for selected state */
}

.cartaddbox.unselected {
  background-color:#219C90 !important;
   border: none !important; /* Change this to the desired color for unselected state */
}

.sale-containerdiscount {
  position: absolute;
  background-color: rgba(235, 25, 25, 0.826);
  border-radius: 25px;
  font-size: 15px;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 20px;
  color: white;
}
.h3{
  font-weight: 400 !important;
}

.sale-containersoldout {
  position: absolute;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
  font-size: 15px;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 20px;
  color: white;
}

.product-image-container:hover .sale-containerhover {
  display: flex;
}
.product-image-container:hover .sale-containerhovernew {
  display: flex;
}

.cartadd {
  margin-left: 100px;
}

.cartaddbox {
  background-color: #ffffff !important;
  color: #f8f8f8;
}

.products {
  margin-top: 10px;
  margin-bottom: 50px;
 
  h3 {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .product-displays {
    gap: 50px;

    .product-card {
      display: flex;
      flex-direction: column;
      gap: 1px;
      border: 1px solid transparent;
     // background-image: linear-gradient(white, white), linear-gradient(45deg, #ffcc70, #ff6f69);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      .product-card:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
      // .product-namecart{
      //   border: 1px solid black;
        
      //   margin-right: 10px;
      
      //   height: 55px;
      // }
      // .centerr{
      //   justify-items: center;
      //   align-items: center;
      //   display: flex;

      // }

      .product-namecart {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px; /* adjust as needed */
        width:95%; /* adjust as needed */
        border: 1px solid #000; /* optional border */
        background-color: #f8f8f8; /* optional background color */
        //margin: 0 auto; /* center the box horizontally */
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
    }
    
    .centerr {
        text-align: center;
        padding-top: 15px;
    }
    
      
      .namemain{
        margin-left: 10px;
      }
      .product-image-container {
        position: relative;
       margin: 10px;
      

        img.default {
          height: 290px;
          
          
          width: 100% !important;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        img.hover {
          display: block;
          height: 290px;
          width: 100% !important;
          object-fit: cover;
          opacity: 0;
          transition: 0.3s ease-in-out;
          position: absolute;
          top: 0;
          left: 0;
        }

        .product-image-container {
          position: relative;
        }

        &:hover {
          img.default {
            opacity: 0;
          }

          img.hover {
            opacity: 1;
          }
        }
      }

      .product-name {
        font-size: 14px;
        color: rgb(0, 0, 0) !important;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .product-price-details {
        display: flex;
        gap: 20px;
        align-items: center;

        .price {
          color: lightgray;
          font-size: 15px;

          span {
            margin-right: 10px;
          }
        }

        .discounted-price {
          font-size: 16px;
          font-weight: 400;

          span {
            margin-right: 10px;
          }
        }
      }

      &:hover {
        .product-name {
          h6 {
            display: inline-block;
            border-bottom: 1px solid black;
          }
        }
      }
    }
  }
}

.productNewarrivals {
  margin-top: 10px;
  margin-bottom: 50px;

  h4 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .product-displays {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;

    .product-card {
      display: flex;
      flex-direction: column;
      gap: 1px;
      padding: 0px;
      border: 1px solid transparent;
     // background-image: linear-gradient(white, white), linear-gradient(45deg, #ffcc70, #ff6f69);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      .product-card:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
      

      .product-image-container {
        position: relative;

        img.default {
          height: 290px;
          width: 100% !important;
          object-fit: contain;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        img.hover {
          display: block;
          height: 290px;
          width: 100% !important;
          object-fit: contain;
          opacity: 0;
          transition: 0.3s ease-in-out;
          position: absolute;
          top: 0;
          left: 0;
        }

        .product-image-container {
          position: relative;
        }

        &:hover {
          img.default {
            opacity: 0;
          }

          img.hover {
            opacity: 1;
          }
        }
      }

      .product-name {
        font-size: 14px;
        color: grey;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .product-price-details {
        display: flex;
        gap: 20px;
        align-items: center;

        .price {
          color: lightgray;
          font-size: 15px;

          span {
            margin-right: 10px;
          }
        }

        .discounted-price {
          font-size: 16px;
          font-weight: 400;

          span {
            margin-right: 10px;
          }
        }
      }

      &:hover {
        .product-name {
          h6 {
            display: inline-block;
            border-bottom: 1px solid black;
          }
        }
      }
    }
  }
}

.text-center {
  #button {
    background-color: black;
    color: white;
    width: 120px;
    padding: 10px 20px;
    font-size: 15px;
  }
}

.about-us {
  margin-top: 100px;

  .about-details {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;

    h1 {
      font-weight: 700;
    }

    p {
      max-width: 800px;
      color: rgb(132, 130, 130);
      font-size: 15px;
    }
  }
}

@media (max-width: 1230px) {
  .sale-containerhover {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(255, 232, 167, 0.826);
    border-radius: 25px;
    font-size: 15px;
    z-index: 9999;
  }

  .productNewarrivals {
    margin-top: 10px;
    margin-bottom: 50px;

    h4 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .product-displays {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 50px;

      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-image-container {
          position: relative;

          img.default {
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
          }

          .product-image-container {
            position: relative;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .product-price-details {
          display: flex;
          gap: 20px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 15px;

            span {
              margin-right: 10px;
            }
          }

          .discounted-price {
            font-size: 16px;
            font-weight: 400;

            span {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .sale-containerhover {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(255, 232, 167, 0.826);
    border-radius: 25px;
    font-size: 15px;
    z-index: 9999;
  }

  .productNewarrivals {
    margin-top: 10px;
    margin-bottom: 50px;

    h4 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .product-displays {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      gap: 50px;

      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-image-container {
          position: relative;

          img.default {
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
          }

          .product-image-container {
            position: relative;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .product-price-details {
          display: flex;
          gap: 20px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 15px;

            span {
              margin-right: 10px;
            }
          }

          .discounted-price {
            font-size: 16px;
            font-weight: 400;

            span {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }

  .products {
    margin-top: 10px;
    margin-bottom: 50px;

    .product-displays {
      gap: 50px;

      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-image-container {
          position: relative;

          img.default {
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: cover;
            top: 0;
            left: 0;
          }

          .product-image-container {
            position: relative;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-decoration: none !important;
        }

        .product-price-details {
          display: flex;
          gap: 20px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 15px;

            span {
              margin-right: 10px;
            }
          }

          .discounted-price {
            font-size: 16px;
            font-weight: 400;

            span {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .products {
    .product-displays {
      max-width: 700px;
    }
  }
}

@media (max-width: 431px) {
  .products {
    margin-top: 50px;
    margin-bottom: 30px;

    .product-displays {
      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .product-image-container {
          position: relative;

          img.default {
            height: 180px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 180px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
          }

          .sale-container {
            position: absolute;
            bottom: 5px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            background-color: black;
            border-radius: 25px;
            color: aqua;
            font-size: 15px;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          h6 {
            font-size: 15px;
          }
        }

        .product-price-details {
          display: flex;
          gap: 10px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 13px;

            span {
              margin-right: 10px;
              font-size: 13px;
            }
          }

          .discounted-price {
            font-weight: 400;
            font-size: 13px;

            span {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {

  .sale-containerdiscount {
    position: absolute;
    background-color: rgba(235, 25, 25, 0.826);
    border-radius: 25px;
    font-size: 12px;
    top: 0;
    right: 0;
    margin-right: 5px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: 18px;
    color: white;
  }
  .sale-containersoldout {
    position: absolute;
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    font-size: 12px;
    top: 0;
    left: 0;
    margin-left: 5px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: 18px;
    color: white;
  }


  .sale-containerhover {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 30px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(255, 232, 167, 0.826);
    border-radius: 25px;
    font-size: 15px;
    z-index: 9999;
  }

  .productNewarrivals {
    margin-top: 10px;
    margin-bottom: 50px;

    h4 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .product-displays {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-image-container {
          position: relative;

          img.default {
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
          }

          .product-image-container {
            position: relative;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-decoration: none !important;
        }

        .product-price-details {
          display: flex;
          gap: 20px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 15px;

            span {
              margin-right: 10px;
            }
          }

          .discounted-price {
            font-size: 16px;
            font-weight: 400;

            span {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}

.bounce{
  transition: transform 0.3s ease-in-out, 

}

.bounce:hover {
  transform: scale(1.05);

}

.star-rating {
  display: inline-flex;
  font-size: 2rem;
  cursor: pointer;
}

.star {
  color: #ccc; /* Default star color */
  transition: color 0.2s;
}

.star.filled {
  color: #f39c12; /* Filled star color */
}



// CommentSection.scss
.comment-section {
  margin-top: 20px;

  h3 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background: #f9f9f9;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    textarea {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      resize: none;
    }

    button {
      padding: 10px;
      background: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #0056b3;
      }
    }
  }
}
.product-namecarsst {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* adjust as needed */
  width:95%; /* adjust as needed */
  border: 1px solid #000; /* optional border */
  background-color: #f8f8f8; /* optional background color */
  //margin: 0 auto; /* center the box horizontally */
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.centerrr {
  text-align: center;
  padding-top: 15px;
}





.highlights-carousel {
  margin: 20px auto;
  max-width: 400px;
}

.highlight-item {
  text-align: center;
  
}


.highlight-item img {
  width: 100px; /* Set both width and height to the same value */
  height: 100px; 
  border-radius: 50%; /* This creates the circular shape */
  margin-bottom: 10px;
  object-fit: cover; /* Ensures the image maintains its aspect ratio */
}
.instagram-size{
  width:40px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bc1888;
  background-color: transparent !important; /* Your custom color */
  border-color: transparent !important ; /* Border color matching the button */
  color: #fff; /* Text color */
}
.instagram-image{
  width:50px;
  height:50px;
 
}


.connect-button{
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
  border: none !important;
  width:250px;
  border-radius: 30px !important;
  border-right: 5px solid #868181 !important;
  border-bottom: 5px solid #868181 !important;

}
.reviews-button {
  border-right: 5px solid #868181 !important;
  border-bottom: 5px solid#868181 !important;
  border-radius: 30px !important;
  width:250px;
  font-size: 12px; 
  padding: 10px 20px;
}
.shadow-box {
  align-items: center;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Darker shadow */
  padding: 20px; /* Optional: To add some space around the buttons */
  border-radius: 10px; /* Optional: To round the corners */
  background-color: whitesmoke; /* Optional: Light background to make shadow more visible */
}
.contact-status{
  text-align: center;
  align-items: center;
  justify-content: center;
}
.no-highlights{
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 760px) {
  .review-button {
    /* Decrease button size */
    font-size: 14px; /* Adjust for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .contact-status .row {
    display: flex;
    flex-direction: column; /* Stack the items vertically */
  }

  .contact-status .col-md-6 {
    /* Center align items in smaller viewports */
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Add space between items */
  }
}

.product-mainnames{
  text-decoration: none !important;
}
