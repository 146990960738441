.contact-page{
   width: 800px !important;
   margin-top: 50px;
   margin-bottom: 50px;
   display: flex;
   flex-direction: column;
   gap: 50px;
   h1{
    font-size: 55px;
    font-family:'Times New Roman', Times, serif;
   }
   p{
    color: grey;
    font-size: 15px;
    a{
        color: grey;
        margin-left: 10px;
    }
   }
   h4{
    font-weight: 600;
    font-size: 18px;
   }

   form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .form-inputs{
        display: flex;
        gap: 20px;
        input{
           width: 100%; 
           padding: 10px;
        }
        textarea{
            width: 100%;
            padding: 10px;
            height: 100px;
        }
    }
    button{
        display: flex;
        width: 120px;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: black;
        color: white;
    }
   }
}

@media(max-width:580px){
   .contact-page {
    width: unset !important;
    form{
        width: 100%;
        .form-inputs{
            display: flex;
            flex-direction: column;
            input{
                width: 100% !important;
            }
        }
    }
   }
}