.Footerstyle {
  background: linear-gradient(180deg, rgba(255, 0, 0, 0.1) 14%, rgba(0, 255, 255, 0.1) 85%);
  padding: 10px 0;
  color: #5e5454;

  .container {
    .row {
      .col-md-4, .col-sm-12 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
      }

      .logo {
        width: 200px;
        padding: 5%;
      }

      h5 {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }

      .footer-links {
        list-style-type: none;
        padding: 0;

        li {
          margin: 10px 0;

          a {
            color: #5e5454;
            text-decoration: none;
            &:hover {
              color: #ff0000;
              text-decoration: underline;
            }
          }
        }
      }

      p {
        font-size: 14px;
        color: #5e5454;
        line-height: 1.5;
        margin: 0;
      }
    }
  }

  .powered {
    margin-top: 5px;
    color: #5e5454;
    font-size: 12px;
  }
}
