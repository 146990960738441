body{
    background: rgb(251 214 214);
}
#media-show{
    display: none;
}
.border {
    border: 1px solid;
    background-color: rgb(239, 158, 158);
    color: rgb(93, 90, 90);
}

.box-border{
    border:1px solid;
    background-color: rgb(241, 237, 237);
    outline: none;
    border: none;
    border-radius: 4px;
}

.bd{
    border-bottom: 1px solid rgb(251, 245, 245);
    border-radius: 4px;
    box-shadow: rgba(239, 230, 230, 0.945) 0px 4px 1px;
}

.pay{
    background-color:white;
    border-radius: 5px;
}

.pay:hover{
    background-color: rgb(241, 237, 237);
    cursor: pointer;
}

.br{
    border-right: 1px solid rgba(122, 122, 122, 0.222);
}
.bb-dot{
    border-top: 1px solid rgba(141, 19, 19, 0.222);   
}
.order{
    font-size: 12px;
    font-family: "Poopins";
    color: rgba(65, 64, 64, 0.753);
}
.order-name{
    font-size: 18px;
    font-family: "Poopins";
    color: rgba(10, 10, 10, 0.753); 
}

.amt{
    font-size: 14px;
    font-family: "Poopins";
    color: rgba(22, 22, 22, 0.753);
}

.fixed {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top:8%;
}
.nav-link-gap {
   margin:1px !important;
   font-size: 15px;
   color: white;
   font-weight: 500;
}
.modal-body p{
    color: rgba(89, 89, 89, 0.534);
    font-family:"Poppins";
    font-weight: 500;
}

.nav-link-gap:hover {
    color:skyblue !important;
 }

.credit-icon{
    width: 33.92px;
}

@media  (max-width:800px)
{
    .credit-icon{
        width: 25.92px;
    }  
}
@media (max-width:991px){
    #media-show{
        display: block;
    }
   
    
}
@media (max-width:990px){
    // #media-show{
    //     display: block;
    // }
    #media-hide{
        display: none;
    }
    
}


.skeleton {
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    animation: loading 1s infinite alternate;
}

.skeleton-image {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    border-radius: 50%;
}

.skeleton-text {
    width: 100px;
    height: 16px;
    background-color: #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

.skeleton-icon {
    width: 50px;
    height: 50px;
    background-color: #ddd;
    border-radius: 50%;
}

@keyframes loading {
    0% {
        opacity: 3.5;
    }
    100% {
        opacity: 1;
    }
}


.quaitystyle{
    background-color:black;
    position: absolute;
    border-radius: 80px;
    height: 15px;
    width: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 8px;
    margin-left: 40px;
    margin-top: -50px;

color: white;
}

.thankyou {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgb(251 214 214);
  
    .btn-black {
      background-color: black;
      border-color: black;
    }
  
    .btn-black:hover {
      background-color: #333;
      border-color: #333;
    }
  }
  .paynow-button{
    background-color: black !important;
    color: white !important
  }
  