body {
    margin: 0;
    font-family: 'Playfair Display', serif; /* Apply the Google Font */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a{
    text-decoration: none !important;
  }
  