.shopify-terms{
    width: 600px !important;
    margin-top: 50px;
    margin-bottom: 50px;
    h5{
        font-weight: 700;
        margin-top: 50px;
        color: rgb(48, 48, 48);
    }
    h6{
        font-weight: 700;
    }
    ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}