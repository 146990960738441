
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.category-row {
  display: flex;
  justify-content: space-between; /* Distributes space evenly */
  align-items: center; /* Aligns items vertically in the center */
  gap: 15px; /* Optional spacing between items */
}

.category-item {
  flex: 1; /* Makes all items equal width */
  min-width: 120px; /* Ensures items do not shrink too much */
}



.maindivall{
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  gap: 15px; /* Optional spacing between items */

}
.sizeframe{
  font-size: 12px !important;
}


.boss-header {
    padding: unset !important;
  
    .text-center {
      font-size: 14px;
      padding: 10px;
      background-color: #dbade6;
      margin: unset;
      box-shadow: inset;
    }
  
    .navmainstyle {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: white;

    }
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .navbar {
        flex-grow: 1;
      }
  
      .logostyle {
        width: 150px;
        height: 45px;
        object-fit: fill;
      }
  
      .header-icons {
        display: flex;
        gap: 20px;
        align-items: center;
      
      }
  
      .sideicon{
        width: 20px;
        height: 25px;
        object-fit: contain;
        color: #f01426;
        cursor: pointer;
      }

      .sideicondiv :hover{
        color: black;
      }
      .navbar-toggler {
        border-color: white;
      }
  
      .nav-link {
        font-size: 15px;
        color: rgb(18 18 18 / 75%);
        transition: color 0.3s, text-decoration 0.3s;
  
        &:hover {
          text-decoration: underline;
        }
  
        &.selected {
          text-decoration: underline;
        }
      }
  
      .badgestyle {
        background-color: black;
        position: absolute;
        border-radius: 80px;
        height: 15px;
        width: 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 8px;
        margin-left: 13px;
        margin-top: -36px;
        color: white;
      }

      .badgestylemodal {
        background-color: black;
        position: absolute;
        border-radius: 80px;
        height: 15px;
        width: 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 8px;
        margin-left: 13px;
        margin-top: -36px;
        color: white;
      }

      .badgestylecart {
        background-color: black;
        position: absolute;
        border-radius: 80px;
        height: 15px;
        width: 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 8px;
        margin-left: 16px;
        margin-top: -36px;
        color: white;
      }
    }
  }

  
  
  .mobile-px {
    background-color: white;
    height: 100vh;
    z-index: 99;
  
    label {
      padding: 15px 25px;
      font-size: 15px;
      color: rgb(18 18 18 / 75%);
      transition: color 0.3s, text-decoration 0.3s;
    }
  
    .mobinner:hover {
      background-color: #e2e2e2;
      width: 100%;
    }
  }


  
  @media (min-width: 992px) {
    .mobile-px {
      display: none;
    }
  
    .modalmain {
      height: 120px;
      min-width: 1400px;
      max-width: 900px;
      background-color: red;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 1200px !important;
      height: 50px;
      margin: 40px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 160vh;
      position: absolute;
    }
  }
  
  .modalmain {
    height: 120px;
    min-width: 900px;
    max-width: 900px;
    background-color: #ffffff;
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  
  .inputcontrol {
    width: 1100px !important;
    height: 50px;
    margin: 40px;
    border: 2px solid black !important;
  }
  
  .searchimg {
    margin-left: 800px;
    position: absolute;
  }
  
  .searchcentre {
    align-items: center;
    justify-content: center;
    padding: 20px;
    display: flex;
  }
  
  @media (max-width: 992px) {
    .modalmain {
      height: 120px;
      min-width: 0px;
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 80% !important;
      height: 50px;
      margin: 40px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 350px;
      position: absolute;
    }
  }
  
  @media (max-width: 540px) {
    .modalmain {
      height: 120px;
      min-width: 0px;
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 80% !important;
      height: 50px;
      margin: 20px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 250px;
      position: absolute;
    }
  }
  
  @media (min-width: 1024px) {
    .modalmain {
      height: 120px;
      min-width: 900px;
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 80% !important;
      height: 50px;
      margin: 40px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 50vh;
      position: absolute;
    }
  }
  
  @media (min-width: 1080px) {
    .modalmain {
      height: 120px;
      min-width: 1240px;
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 80% !important;
      height: 50px;
      margin: 40px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 920px;
      position: absolute;
    }
  }


   @media (min-width: 1080px) {
    .modalmain {
      height: 120px;
      min-width: 1240px;
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  
    .inputcontrol {
      width: 80% !important;
      height: 50px;
      margin: 40px;
      border: 2px solid black !important;
    }
  
    .searchimg {
      margin-left: 920px;
      position: absolute;
    }
  }
  
  // @media (max-width: 768px) {

  //   .sideicondiv{
  //       display: none;
  //   }
  // }

  

  .sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Adjust if needed */
    z-index: 1000; /* Ensure it's above other content */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding-bottom: 0px;
    background-color: rgb(255, 255, 255)
  }
/* CSS for dropdown functionality */
/* Refined CSS for dropdown functionality */
.dropdown-container {
  // position: relative;
  // display: inline-block;
  // padding-top: 10px;
//background-color: #580fb7;
  cursor: pointer;
  // padding-left: 20px;
  z-index: 9999;
  padding: 10px;
  height: 55px;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100px;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 9999;
  border-radius: 4px;
  padding: 10px  !important;
  top: 100%; /* Aligns dropdown just below the container */
  left: 0;
  width: 100% !important;
}

.dropdown-content p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.dropdown-content p:hover {
  background-color: #f1f1f1;
}

/* Show dropdown on hover over the container */
.dropdown-content {
  display: block;
}

.hovereffecthead {
  color: #2596BE !important;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
  position: relative; /* For the underline effect */
  font-size: 20px !important;
}

.hovereffecthead:hover {
  color: #050505 !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow */
  transform: scale(1.05); /* Slightly increase the size */
  
}

/* Add an underline effect */
.hovereffecthead::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 3px; /* Adjust as needed */



  left: 0;
  background: #010101;
  transition: width 0.3s ease;
}

.hovereffecthead:hover::after {
  width: 100%;
}

.categorysection {
  white-space: nowrap;
  margin: 5px;
  text-align: center;
}

.badgemodal{
  display: flex !important;
  justify-content: space-between  !important;

}

.badgestylemodal {
  background-color: black;
  
  border-radius: 80px;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 8px;
 
  color: white;
}

.hovereffectheadsize{
  font-size: 16px !important;
  color: #b00303 !important;
  font-weight: 400;  
}
.headline{
  text-decoration: none !important;
}

@media (min-width: 375px) {
  .sizeframe{
    font-size: 9px;
    color: #b00303 !important;
  }
  .hovereffecthead {
   
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
    position: relative; /* For the underline effect */

    font-size: 20px;
  }
  .logostyle {
    width: 10px;
    height: 35px;
    object-fit: fill;
  }
}
@media (max-width: 1024px) {
  .hovereffectheadsize {
    font-size: 18px; /* Decrease font size for tablet screens */
  }
  .maindivall{
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hovereffectheadsize {
    font-size: 13px;
  }
  .maindivall{
    display: flex;
    align-items: center;
    flex-direction: row; /* Ensure it's still in a row on smaller screens */
    justify-content: center;
  }
}

@media (max-width: 526px) {
  .hovereffectheadsize {
    font-size: 12px !important ; /* Decrease font size for mobile phones */
  }
  .maindivall{
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

@media (max-width: 375px) {
  .hovereffectheadsize {
    font-size: 11px !important; /* Adjust for very small screens */
  }
  .maindivall{
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
.header-searchbox{
  padding-bottom: 20px;
  margin-left: 15%;
}



.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
}

.category-item:hover .dropdown-content {
  display: block;
}

.headline {
  font-size: 16px;
  text-decoration: none;
  color: #333;
}

.sizeframe {
  cursor: pointer;
  padding: 5px 0;
}

.sizeframe:hover {
  background-color: #f0f0f0;
}









// .dropdown-content {
//   border: 1px solid #ccc;
//   padding: 10px;
//   margin-top: 5px;
//   border-radius: 5px;
//   background-color: #fff;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// }

.sizeframe {
  margin: 5px 0;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sizeframe:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .hovereffectheadsize {
    display: none;
  }
}

.newlook-heading{
  padding:20px !important; 
  font-weight: bolder;
  color:brown !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
}
.newlook-headings{
  color:brown !important;
}


